// src/i18n.js
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const resources = {
  en: {
    translation: {
      home: "Home",
      about: "About Me",
      projects: "Projects",
      contact: "Contact Me",
      follow: "Follow me",
      hi: "Hi,",
      iamAmare: "I'm Amare.",
      amareTeklay: "Amare Teklay",
      email: "E-mail:",
      phone: "Phone:",
      title: "Economist and Software Developer",
      intro:
        "I'm a Senior Research Assistant at the Beijer Institute of Ecological Economics in Stockholm. With a passion for multidisciplinary research, I specialize in experimental and environmental economics and have extensive programming experience. I'm cautiously optimistic about AI and its potential to drive positive change.",
      explore:
        "Explore my work, education, skills, and projects to learn more about my journey and contributions to the field.",
      more: "Read More",
      whyEconomicsTitle: "Why Economics?",
      understandingTitle: "Understanding the Complexities of the World",
      economicsDescription:
        "Economics is the backbone of decision-making processes, shaping policies, business strategies, and individual choices. My passion for economics stems from a deep curiosity about how markets operate, how resources are allocated, and how economic policies can impact societies at large. I believe that a strong foundation in economics equips me with the analytical tools necessary to navigate and influence the complex, interconnected systems that drive our global economy. It's not just about numbers; it's about understanding the human behaviors and institutional dynamics that underpin economic activity.",
      whySoftwareTitle: "Why Software Development?",
      buildingTitle: "Building Solutions for the Future",
      softwareDescription:
        "Software development is the art and science of solving problems through technology. My journey into software development began with a desire to create and innovate, to transform ideas into tangible, impactful solutions. The ability to write code that can automate processes, connect people, and improve lives is what drives my passion for this field. Software development allows me to blend creativity with logic, to continuously learn and adapt in a fast-paced environment. By integrating my economic insights with programming skills, I aim to build tools and applications that not only perform tasks but also enhance decision-making and efficiency across various domains.",
      aboutMeIntro:
        "I am a researcher and developer with a diverse background in economics, environmental science, and software engineering. My academic journey began in Ethiopia, where I earned a BA in Economics, followed by an MSc in Economics and a BSc in Software Engineering. My curiosity for economic research led me to pursue a PhD in Economics from the Norwegian University of Life Sciences, focusing on incentivized forest conservation through spatial econometric and experimental methods.",
      aboutMeProfessional:
        "Professionally, I have held various roles, including as a Lecturer at Mekelle University, a Postdoctoral Researcher at the Swedish University of Agricultural Sciences, and now as a Senior Research Assistant at the Beijer Institute of Ecological Economics. My work primarily revolves around exploring the intersection of economics and environmental science, with a strong emphasis on data analysis and programming.",
      aboutMeSkills:
        "Outside of research, I have honed my full stack development skills, completing a diploma in Full Stack Software Development from the Code Institute, Ireland. This unique blend of skills allows me to approach problems from both a technical and analytical perspective, making me adept at handling complex data-driven projects.",
      aboutMeHobbies:
        "In my free time, I enjoy exploring new technologies, contributing to open-source projects, and staying updated on advancements in AI and machine learning.",
      downloadResume: "Download My Resume",
    },
  },
  sv: {
    translation: {
      home: "Hem",
      about: "Om mig",
      projects: "Projekt",
      contact: "Kontakta mig",
      follow: "Följ mig",
      hi: "Hej,",
      iamAmare: "Amare heter jag.",
      amareTeklay: "Amare Teklay",
      email: "epost:",
      phone: "Telefon:",
      title: "Ekonom och Mjukvaruutvecklare",
      intro:
        "Jag är senior forskningsassistent vid Beijer-institutet för ekologisk ekonomi i Stockholm. Med en passion för tvärvetenskaplig forskning specialiserar jag mig på experimentell och miljöekonomi och har omfattande erfarenhet av programmering. Jag är försiktigt optimistisk om AI och dess potential att driva positiv förändring.",
      explore:
        "Utforska mitt arbete, min utbildning, mina färdigheter och projekt för att lära dig mer om min resa och mina bidrag till området.",
      more: "Läs Mer",
      whyEconomicsTitle: "Varför Ekonomi?",
      understandingTitle: "Förståelse för Världens Komplexitet",
      economicsDescription:
        "Ekonomi är grunden för beslutsfattande, som formar politiska strategier, affärsstrategier och individuella val. Min passion för ekonomi kommer från en djup nyfikenhet på hur marknader fungerar, hur resurser fördelas och hur ekonomiska policyer kan påverka samhällen i stort. Jag tror att en stark grund i ekonomi utrustar mig med de analytiska verktyg som behövs för att navigera och påverka de komplexa, sammanlänkade systemen som driver vår globala ekonomi. Det handlar inte bara om siffror; det handlar om att förstå mänskligt beteende och institutionell dynamik som ligger till grund för ekonomisk aktivitet.",
      whySoftwareTitle: "Varför Mjukvaruutveckling?",
      buildingTitle: "Bygga Lösningar för Framtiden",
      softwareDescription:
        "Mjukvaruutveckling är konsten och vetenskapen att lösa problem genom teknik. Min resa in i mjukvaruutveckling började med en önskan att skapa och förnya, att omvandla idéer till konkreta, påverkande lösningar. Förmågan att skriva kod som kan automatisera processer, koppla samman människor och förbättra liv är det som driver min passion för detta område. Mjukvaruutveckling låter mig blanda kreativitet med logik, att kontinuerligt lära mig och anpassa mig i en snabb miljö. Genom att integrera mina ekonomiska insikter med programmeringsfärdigheter strävar jag efter att bygga verktyg och applikationer som inte bara utför uppgifter utan också förbättrar beslutsfattande och effektivitet inom olika områden.",
      aboutMeIntro:
        "Jag är en forskare och utvecklare med en mångsidig bakgrund inom ekonomi, miljövetenskap och mjukvaruteknik. Min akademiska resa började i Etiopien, där jag tog en kandidatexamen i ekonomi, följt av en magisterexamen i ekonomi och en kandidatexamen i mjukvaruteknik. Min nyfikenhet för ekonomisk forskning ledde mig till att doktorera i ekonomi vid Norges Miljö- och biovetenskapliga universitet, med fokus på incitamentdriven skogskonservering genom rumsliga ekonometriska och experimentella metoder.",
      aboutMeProfessional:
        "Professionellt har jag haft olika roller, inklusive som lektor vid Mekelle University, postdoktoral forskare vid Sveriges lantbruksuniversitet och nu som senior forskningsassistent vid Beijer-institutet för ekologisk ekonomi. Mitt arbete kretsar främst kring att utforska samspelet mellan ekonomi och miljövetenskap, med starkt fokus på dataanalys och programmering.",
      aboutMeSkills:
        "Utanför forskningen har jag finslipat mina färdigheter inom fullstack-utveckling och avslutat en examen i Full Stack Software Development från Code Institute, Irland. Denna unika kombination av färdigheter gör att jag kan närma mig problem både från ett tekniskt och analytiskt perspektiv, vilket gör mig skicklig på att hantera komplexa datadrivna projekt.",
      aboutMeHobbies:
        "På min fritid tycker jag om att utforska ny teknik, bidra till open-source-projekt och hålla mig uppdaterad om framsteg inom AI och maskininlärning.",
      downloadResume: "Ladda ner mitt CV",
    },
  },
  ti: {
    translation: {
      home: "ቤት",
      about: "ብዛዕባይ",
      projects: "ፕሮጀክታት",
      contact: "ርኸቡኒ",
      follow: "ተኸተሉኒ",
      hi: "ሰላም!",
      iamAmare: "ኣማረ ይበሃል።",
      amareTeklay: " ኣማረ ተኽላይ",
      email: "ኢ-ፖስታ:-",
      phone: "ስልኪ:-",
      title: "ክኢላ ስነ-ቊጠባን ሶፍትዌርን",
      intro:
        "ኣነ ናይ ሰነ-ምርምር ሓጋዚ ኣብ ቤጀሪ ኢንስቲትዩት ኤኮሎጂካል ኢኮኖሚክስ ናይ ስቶክሆልም እየ። ብዙሕ መጽናዕታዊ ምርምራት ስለ ዝፈቱ ፡ ኣብ ፈተነታትን ከባብያዊ ቊጠባን ፍሉይ ተመኩሮ ናይ መደብ ፕሮግራም ኣሎኒ ። ብዛዕባ AI ጥንቁቕን ኣወንታዊ ለውጢ ናይ ምዝውታር ተኽእሎ ዘለዎን እየ ።",
      explore:
        "ብዛዕባ ጕዕዞይን ኣበርክቶይን ዝያዳ ምእንቲ ክፈልጥ ፡ ስርሐይን ትምህርተይን ክእለተይን ፕሮጀክትታተይን ዳህስስ ።",
      more: "ተወሳኺ የንብቡ",
      whyEconomicsTitle: "ስለምንታይ ኢኮኖሚክስ?",
      understandingTitle: "ዝግጅቶ እኩይ ከባቢ መስጠት",
      economicsDescription:
        "ኢኮኖሚክስ እቲ ትካል ኣብ ስምምዕና ውጽእቲ, ፖሊሲ እታ ከይተቓልቦ ንቀላጽ, ቀረባ ትካልን መንጎላግ በቀሊሉ ምዝምት እንተይደኣ ኢኮኖሚክስ ዝምረት እዩ። ብምጽንታ ስለምንታይ ኣብ ምምሕራብ ዕቤትን ኩብትን ኣእዝን ኣብ መስርሕነታት ስምምዕን ኣብ ተፍለይታታትን እዩ።",
      whySoftwareTitle: "ስለምንታይ ሶፍትዌር አንዳርተር?",
      buildingTitle: "ሕርያት ብምህካእ ንምድርስ",
      softwareDescription: "ሶፍትዌር አንዳርተር እቲ ኣርትና ሳይንስ",
      aboutMeIntro:
        "ኣነ ኣሰላሰለይና ሰነቓሰነታዊ ተመሃሮ ብልክን ዝበለታ እንደትም ዘይወስነ ናይ ኢኮኖሚክስ፣ ኣካብቲ ኣሕትምን እና ሶፍትዌር አንዳርተር ብልክን ዝምስላል። ናይቲ ትምህርቲ ጉዕይ ኣብ ኢትዮጵያ ንዝጀመረ፣ ብኣንቡቡሲዎን ትምህርቲ ኢኮኖሚክስን፣ ንቕረብታይ ትምህርቲ እና ንሶፍትዌር አንዳርተር ኣብቲ ምምሕራብ እዩ። ናይ ትምህርቲ ጥራሕ ተፈላጥ ናብ ክቡር ናይ ኢኮኖሚክስ ንምምሕራብ ከተጠዓማሞም ትምህርቲ እዩ።",
      aboutMeProfessional:
        "ኣብ ስራሕነተይ ኣብ ስራሕነተይ ብልክን ኣብ ስምምዕና ኣብ ተሓሳሳይ እዩ፣ ከይ እትጠቕምቲ ንንዓርናተይ ኢኮኖሚክስን እና ኢኮኖሚክስን ንክቡር እና ኢኮኖማት ንክቡር እና ኢኮኖምናትን። ንኣብቲ ናይ ኢኮኖሚክስ ንስራሕ እቲ ኢኮኖማታትን ንክቡር ንምምሕራብ ኣእዛን ተዋጾምናን እዩ።",
      aboutMeSkills:
        "ካብ ኣብቲ ምምሕራብ ኣሕትምን ኣብቲ ብምስለኡ ንምምሕራብ ትካላትን ትካላትን ተመሃሮ እዩ። ብልክን ትካላትን ትምህርቲ ኢኮኖምናት ንክቡር እዩ። እቲ ንክቡር እቲ ተሓሳሳይ እና ንክቡር እቲ ብልክን ንክቡር እና ንክቡር እዩ።",
      aboutMeHobbies:
        "ካብቲ ንብልክን ኣብቲ ትምህርቲ ኢኮኖምናትን እና ንክቡር እና ንክቡር ንክቡር ንክቡር እና ንክቡር ንክቡር እዩ።",
      downloadResume: "ኣብቲ ኣብቲ ንምምሕራብ",
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
