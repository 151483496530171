// src/pages/Contact.js
import React from "react"
import "../components/styles/global.css"
import ContactCard from "../components/ContactCard"

const Contact = () => {
  return (
    <div className="contact-container">
      <ContactCard />
    </div>
  )
}

export default Contact
