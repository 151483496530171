// src/index.js
import "./index.css"
import "./Colors.css" // Add this line
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import CssBaseline from "@mui/material/CssBaseline"
import "./i18n" // Import i18n configuration

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
