// src/pages/About.js
import React from "react"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import Link from "@mui/material/Link"
import "../components/styles/global.css"
import { Container } from "@mui/material"
import { useTranslation } from "react-i18next"

const About = () => {
  const { t } = useTranslation()
  return (
    <Container
      sx={{
        paddingLeft: "140px !important",
        paddingRight: "120px !important",
      }}
    >
      <div className="about">
        <Avatar
          alt="Your Name"
          src={`${process.env.PUBLIC_URL}/images/amare.jpg`} // Replace with the path to your image
          className="about-avatar"
        />{" "}
        <Typography variant="h4" className="about-title" gutterBottom>
         {t("about")}
        </Typography>
        <Container
          className="about-container"
          sx={{
            borderLeft: "2px #f84817 solid",
            borderRight: "2px #f84817 solid",
            borderRadius: "5%",
            padding: "30px !important",
            paddingLeft: "60px !important",
            paddingRight: "60px !important",
          }}
        >
          <Typography variant="body1" className="about-description">
            {t("aboutMeIntro")}
          </Typography>
          <Typography className="about-description">
           {t("aboutMeProfessional")}
          </Typography>
          <Typography className="about-description">
           {t("aboutMeSkills")}
          </Typography>
          <Typography className="about-description">
           {t("aboutMeHobbies")}
          </Typography>
          <Link href="/Amaresume.pdf" download underline="hover">
            {t("downloadResume")}
          </Link>
        </Container>
      </div>
    </Container>
  )
}

export default About
