import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import SocialMediaLinks from "./SocialMediaLinks"
import "./styles/global.css"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const { t } = useTranslation()
  return (
    <Box className="footer" p={4} textAlign="center">
      {/* First Row: Three Columns */}
      <div
        style={{
          padding: "20px",
          borderLeft: "2px #f84817 solid",
          borderRight: "2px #f84817 solid",
          borderRadius: "20%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("contact")}
            </Typography>
            <div
              style={{
                padding: "20px",
                borderRight: "2px #f84817 solid",
                borderRadius: "20%",
              }}
            >
              <Typography variant="body1">
                {t("email")}{" "}
                <Link href="mailto:amare.hailu@kva.se">amare.hailu@kva.se</Link>
              </Typography>
              <Typography variant="body1">
                {t("phone")} +46 765 250 445
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" className="footer-text">
              {/* Add any additional content here if needed */}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">{t("follow")}</Typography>
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </div>
      {/* Second Row: Copyright */}
      <Box mt={4}>
        <Typography variant="body2" className="footer-text">
          &copy; {new Date().getFullYear()} {t("amareTeklay")}
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
