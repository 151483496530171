// src/components/Projects.js
import React from "react"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import "../components/styles/global.css"

const Projects = () => {
  return (
    <Container
      sx={{
        paddingLeft: "140px !important",
        paddingRight: "120px !important",
      }}
    >
      <div className="about">
        <Typography variant="h4" gutterBottom className="project-title">
          Selected Projects
        </Typography>
        <Container
          className="projects-container"
          sx={{
            borderLeft: "2px #f84817 solid",
            borderRight: "2px #f84817 solid",
            borderRadius: "5%",
            padding: "30px !important",
            paddingLeft: "60px !important",
            paddingRight: "60px !important",
          }}
        >
          <Typography variant="body2" className="project-description">
            Forest Conservation Strategies Beijer Institute of Ecological
            Economics Led a project on evaluating different forest conservation
            strategies using econometric models and field experiments.
          </Typography>
          <Typography variant="body2" className="project-description">
            Web Application Development Arkus AI, Stockholm Developed a full
            stack web application to streamline data management processes for a
            client in the AI sector.
          </Typography>
          <Typography variant="body2" className="project-description">
            Deforestation Analysis in Indonesia World Congress of Environmental
            and Resource Economists Conducted a spatial econometric analysis to
            identify the key drivers of deforestation in Indonesia.
          </Typography>
        </Container>
      </div>
    </Container>
  )
}

export default Projects
