// src/pages/Home.js
import React from "react"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { Link } from "react-router-dom"
import "../components/styles/global.css"
import { useTranslation } from "react-i18next"

const Home = () => {
  const { t } = useTranslation()
  return (
    <div className="home">
      <Container className="home-container">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <div className="home-image-container">
              <img
                src={`${process.env.PUBLIC_URL}/images/amareth.jpg`} // Replace with the path to your image
                alt="Amare Teklay"
                className="home-image"
              />
              <div className="bubble"></div>
              <div className="bubble"></div>
              <div className="bubble"></div>
              <div className="bubble"></div>
              <div className="bubble"></div>
              <div className="bubble"></div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="home-content">
              <Typography variant="h4" className="home-title" gutterBottom>
                {t("hi")} {t("iamAmare")}
              </Typography>
              <Typography variant="h5" className="home-subtitle">
                {t("title")}
              </Typography>
              <Typography variant="body1" className="home-description">
                {t("intro")}
              </Typography>
              <Typography>{t("explore")}</Typography>
              <Button
                variant="contained"
                className="cta-button"
                component={Link}
                to="/about"
                sx={{ mt: 3 }}
              >
                {t("more")}
              </Button>
            </div>
          </Grid>
        </Grid>
        <Container sx={{ mt: 4, pt: 4, pb: 4 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <div className="home-content">
                <Typography variant="h4" className="home-title" gutterBottom>
                  {t("whyEconomicsTitle")}
                </Typography>
                <Typography variant="h5" className="home-subtitle">
                  {t("understandingTitle")}
                </Typography>
                <Typography>{t("economicsDescription")}</Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="home-content">
                <Typography variant="h4" className="home-title" gutterBottom>
                  {t("whySoftwareTitle")}
                </Typography>
                <Typography variant="h5" className="home-subtitle">
                  {t("buildingTitle")}
                </Typography>
                <Typography>{t("softwareDescription")}</Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ mt: 4, pt: 4, pb: 4 }}>
          <Typography
            variant="h2"
            sx={{
              textAlign: "center",
              mb: 8,
              marginLeft: "40px",
              marginRight: "40px",
              borderLeft: "100px var(--light) solid",
              borderRight: "100px var(--light) solid",
              borderRadius: "20%",
              color: "var(--blue)",
            }}
          >
            {" "}
            Featured Projects
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <div className="home-content">
                <Typography variant="h4" className="home-title" gutterBottom>
                  Research
                </Typography>
                <Typography variant="h5" className="home-subtitle">
                  Behavioral experiments
                </Typography>
                <Typography variant="h5" className="home-subtitle">
                  Simulation
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="home-content">
                <Typography variant="h4" className="home-title" gutterBottom>
                  Side Projects
                </Typography>
                <Typography variant="h5" className="home-subtitle">
                  Programming
                </Typography>
                <Typography variant="h5" className="home-subtitle">
                  AI
                </Typography>
                <Typography variant="h5" className="home-subtitle">
                  Podcast
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  )
}

export default Home
