// src/components/Header.js
import React, { useState } from "react"
import { Link } from "react-router-dom"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { useTranslation } from "react-i18next"
import useMediaQuery from "@mui/material/useMediaQuery"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import "./styles/global.css"

const Header = () => {
  const { t, i18n } = useTranslation()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [language, setLanguage] = useState(i18n.language)
  const isMobile = useMediaQuery("(max-width: 768px)") // Detects if the screen is mobile size

  const logoSrc =
    language === "ti"
      ? `${process.env.PUBLIC_URL}/images/amatlogo.png`
      : `${process.env.PUBLIC_URL}/images/amalogo.png`

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open)
  }

  const handleLanguageChange = (event) => {
    const lng = event.target.value
    i18n.changeLanguage(lng)
    setLanguage(lng)
  }

  const menuItems = (
    <List>
      <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
        <ListItemText primary={t("home")} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/about"
        onClick={toggleDrawer(false)}
      >
        <ListItemText primary={t("about")} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/projects"
        onClick={toggleDrawer(false)}
      >
        <ListItemText primary={t("projects")} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/contact"
        onClick={toggleDrawer(false)}
      >
        <ListItemText primary={t("contact")} />
      </ListItem>
    </List>
  )

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "var(--almost-black)",
        color: "var(--off-white)",
        boxShadow: "none",
      }}
      className="header"
    >
      <Toolbar className="toolbar">
        <Typography
          variant="h6"
          className="logo"
          sx={{ flexGrow: 1, color: "#f84817" }}
        >
          <Link to="/">
            <img
              src={logoSrc}
              alt="Amare Teklay"
              style={{
                borderLeft: "2px #f84817 solid",
                borderRight: "2px #f84817 solid",
                borderRadius: "20%",
                height: "60px",
                width: "240px",
              }}
            />
          </Link>
        </Typography>

        {/* Only show nav-links on larger screens */}
        {!isMobile && (
          <nav className="nav-links">
            <Link to="/" className="nav-link">
              <div className="nav-link-label">{t("home")}</div>
            </Link>
            <Link to="/about" className="nav-link">
              <div className="nav-link-label">{t("about")}</div>
            </Link>
            <Link to="/projects" className="nav-link">
              <div className="nav-link-label">{t("projects")}</div>
            </Link>
            <Link to="/contact" className="nav-link">
              <div className="nav-link-label">{t("contact")}</div>
            </Link>
          </nav>
        )}

        {/* Language Selector */}
        <Select
          value={language}
          onChange={handleLanguageChange}
          sx={{
            marginRight: "20px",
            color: "var(--blue)",
            borderColor: "var(--redish)",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSelect-icon": {
              color: "var(--blue)", // Change this to your desired icon color
            },
          }}
          IconComponent={ArrowDropDownIcon}
        >
          <MenuItem value="en">En</MenuItem>
          <MenuItem value="sv">Sv</MenuItem>
          <MenuItem value="ti">ትግ</MenuItem>
        </Select>

        {/* Only show hamburger menu on smaller screens */}
        {isMobile && (
          <IconButton color="inherit" edge="end" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>

      {/* Drawer for Mobile Menu */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {menuItems}
      </Drawer>
    </AppBar>
  )
}

export default Header
