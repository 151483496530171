// src/components/ContactCard.js
import React from "react"
import Typography from "@mui/material/Typography"
import "./styles/global.css"

const ContactCard = () => {
  return (
      <div className="contact-card">
        <Typography variant="h4" className="home-title" gutterBottom>
          Contact Information
        </Typography>
        <Typography variant="h5" className="home-subtitle">
          Email: amareteklay@gmail.com
        </Typography>
        <Typography variant="body2" >
          Phone: (46) 765250445
        </Typography>
        <Typography variant="body2" >
          Address: Stockholm, Sweden
        </Typography>
      </div>
  )
}

export default ContactCard
