import React from "react"
import FacebookIcon from "@mui/icons-material/Facebook"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import TwitterIcon from "@mui/icons-material/Twitter"
import YouTubeIcon from "@mui/icons-material/YouTube"
import GitHubIcon from "@mui/icons-material/GitHub"
import { IconButton } from "@mui/material"
import "./styles/global.css"

const SocialMediaLinks = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        padding: "20px",
        borderLeft: "2px #f84817 solid",
        borderRadius: "20%",
      }}
    >
      <IconButton
        component="a"
        href="https://www.github.com/amareteklay"
        target="_blank"
        aria-label="GitHub"
      >
        <GitHubIcon
          sx={{
            color: "#2cf8ff",
            "&:hover": {
              border: "2px solid #f84817",
              borderRadius: "20%",
            },
          }}
          fontSize="large"
        />
      </IconButton>
      <IconButton
        component="a"
        href="https://www.facebook.com/amareteklay.hailu"
        target="_blank"
        aria-label="Facebook"
      >
        <FacebookIcon
          sx={{
            color: "#2cf8ff",
            "&:hover": {
              border: "2px solid #f84817",
              borderRadius: "20%",
            },
          }}
          fontSize="large"
        />
      </IconButton>
      <IconButton
        component="a"
        href="https://www.linkedin.com/in/amareteklay"
        target="_blank"
        aria-label="LinkedIn"
      >
        <LinkedInIcon
         sx={{
          color: "#2cf8ff",
          "&:hover": {
            border: "2px solid #f84817",
            borderRadius: "20%",
          },
        }}
          fontSize="large"
        />
      </IconButton>
      <IconButton
        component="a"
        href="https://twitter.com/amareteklay"
        target="_blank"
        aria-label="Twitter"
      >
        <TwitterIcon
         sx={{
          color: "#2cf8ff",
          "&:hover": {
            border: "2px solid #f84817",
            borderRadius: "20%",
          },
        }}
          fontSize="large"
        />
      </IconButton>
      <IconButton
        component="a"
        href="https://www.youtube.com/@anfetpodcast"
        target="_blank"
        aria-label="YouTube"
      >
        <YouTubeIcon
         sx={{
          color: "#2cf8ff",
          "&:hover": {
            border: "2px solid #f84817",
            borderRadius: "20%",
          },
        }}
          fontSize="large"
        />
      </IconButton>
    </div>
  )
}

export default SocialMediaLinks
